import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';

const NAME_PAGE = 'THEMENSCHWERPUNKT';

const Themenschwerpunkt = ({ data }) => (
  <Layout
    title={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.title}
    description={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.description}
  >
    <div className="common-page ot-page">
      <Subheader title={NAME_PAGE} intro={data.datoCmsFixtext.fixtext} />

      {data.allDatoCmsThemenschwerpunkte.edges.map(({ node }) => (
        <div className="ot-template" key={node.id}>
          <div className="ot-template__line">
            <h4 style={{ color: '#fff' }}>
              {NAME_PAGE}: <strong>#{node.hashtag}</strong>
            </h4>
          </div>
          <div className="container">
            <div className="article-feed">
              <article className="article">
                <Link to={`/themenschwerpunkte/${node.link}`}>
                  <div className="post-card">
                    <img src={node.image.url} alt={node.beitragsname} />
                    <div className="post-card__label">
                      <div className="post-card__label__wrapper">
                        <h3>{NAME_PAGE}</h3>
                        <h2 className="title title--white">{node.beitragsname}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="post-caption">
                    <p>{node.caption}</p>
                  </div>
                </Link>
              </article>
            </div>
          </div>
        </div>
      ))}
    </div>
  </Layout>
);

export default Themenschwerpunkt;

export const query = graphql`
  query ThemenschwerpunktQuery {
    allDatoCmsThemenschwerpunkte {
      edges {
        node {
          id
          hashtag
          beitragsname
          image {
            url
          }
          caption
          link
        }
      }
    }

    datoCmsFixtext(name: { eq: "Themenschwerpunkt" }) {
      fixtext
      seo {
        title
        description
      }
    }
  }
`;
